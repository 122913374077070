import { Component, OnInit } from '@angular/core';
import { ContractingTrainingQuickLink } from '../../models/carrier-training-quick-link.models';
import { ContractingQuickLinksService } from '../../services/carrier-training-quick-links.service';

@Component({
  selector: 'app-contracting-v2',
  templateUrl: './contracting-v2.component.html',
  styleUrls: ['./contracting-v2.component.scss']
})
export class ContractingV2Component implements OnInit {
  quickLinks: ContractingTrainingQuickLink[] = [];
  constructor(
    private carrierTrainingQuickLinksService: ContractingQuickLinksService
  ) { }

  ngOnInit(): void {
    this.getQuickLinks();
  }

  getQuickLinks(): void {
    this.quickLinks = this.carrierTrainingQuickLinksService.getPermittedQuickLinks();
  }
}
