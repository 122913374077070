<section class="flex flex-col h-full">
  <section class="flex flex-col w-full h-full mx-auto max-w-1440 p-30 gap-y-20">
    <section class="grid grid-cols-2 md:grid-cols-4 gap-y-20 gap-x-20">
      @for (item of quickLinks; track item; let i = $index) {
        @if (item.Action) {
          <a class="flex items-center cursor-pointer card p-15 gap-x-10 group" (click)="item.Action()" (keypress)="item.Action()" [amplitudeEvent]="item.Name" [tabIndex]="i">
            @if (item.Icon) {
              <i class="flex-none material-icons mi-18">{{item.Icon}}</i>
            }
            @if (item.Image) {
              <img class="w-auto h-18" [src]="item.Image" [alt]="item.Image + ' image'">
            }
            <div class="flex flex-col flex-grow gap-y-2">
              <h2 class="text-sm font-bold group-hover:text-blue-dark">{{item.Name}}</h2>
              <p class="text-sm text-gray">{{item.Description}}</p>
            </div>
          </a>
        }
        @if (item.Link) {
          <a class="flex items-center card p-15 gap-x-10 group" [href]="item.Link" target="_blank" [amplitudeEvent]="item.Name">
            @if (item.Icon) {
              <i class="flex-none material-icons mi-18">{{item.Icon}}</i>
            }
            @if (item.Image) {
              <img class="w-auto h-18" [src]="item.Image" [alt]="item.Name">
            }
            <div class="flex flex-col flex-grow gap-y-2">
              <h2 class="text-sm font-bold group-hover:text-blue-dark">{{item.Name}}</h2>
              <p class="text-sm text-gray">{{item.Description}}</p>
            </div>
          </a>
        }
      }

    </section>
    <app-my-carriers class="flex flex-col h-full overflow-hidden card" tourAnchor="step2"></app-my-carriers>
  </section>
</section>