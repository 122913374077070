import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { ContractingLicensesComponent } from 'src/app/portal/modals/contracting-licenses/contracting-licenses.component';
import { GetContractedComponent } from '../components/get-contracted/get-contracted.component';
import { ContractingTrainingQuickLink } from '../models/carrier-training-quick-link.models';

@Injectable({
  providedIn: 'root'
})
export class ContractingQuickLinksService {

  constructor(private dialog: MatDialog, private router: Router, private permissionService: PermissionService) { }

  getPermittedQuickLinks(): ContractingTrainingQuickLink[] {
    return this.quickLinks.filter(link => !link.Permission || this.permissionService.hasOnePermission(link.Permission));
  }

  // Guids were added to map these links to the records in air table, to use the Action methods defined.
  private quickLinks: ContractingTrainingQuickLink[] = [
    {
      // eebfedfc-a7d3-4746-af78-75cb2e9e5dfa
      Image: './assets/images/icons/surelc-icon.svg',
      Name: 'Contracting Request',
      Description: 'Update or request new contracts via SureLC.',
      Action: (): void => {
        this.dialog.open(GetContractedComponent, {
        });
      },
      Tags: ['contract', 'contracting', 'contracted', 'sureLC', 'licensed', 'appointed']
    },
    // TODO: Noticed Anti Money Laundering Training is not showing up despite hitting all the keyword in global search
    {
      // 8876e505-471b-48d2-a5f0-a70813932e07
      Icon: 'school',
      Name: 'Anti Money Laundering Training',
      Description: 'Access AML training course here (if needed).',
      Link: 'https://www.training.figmarketing.com/post/anti-money-laundering',
      Tags: ['aml', 'training', 'alm', 'anti', 'money', 'contracting', 'contract']
    },
    // TODO: Noticed E&O Training is not showing up despite hitting all the keyword in global search
    {
      // cc00e6b0-6696-48e5-abd0-ac014e228c7f
      Icon: 'school',
      Name: 'E&O Coverage',
      Description: 'Access the IPCG site here to learn more',
      Link: 'https://www.ipcginsurance.com/eo',
      Tags: ['e and o', 'eo', 'coverage', 'contracting', 'contract', 'e&o', 'lockton', 'affinity']
    },
    {
      // 25eb64c4-a6b4-4de0-9f44-4687c30f94a7
      Icon: 'list_alt',
      Name: 'State Licenses',
      Description: 'Access status of current individual licenses here.',
      Action: (): void => {
        this.dialog.open(ContractingLicensesComponent);
      },
      Tags: ['licenses', 'liscense', 'licensing', 'states', 'lisense', 'lisence', 'contracting', 'contract']
    }
  ];
}